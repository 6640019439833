import React, { useEffect } from 'react';
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Header from './components/Header';

const App = () => {
  useEffect(() => {
    // Disable scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Set the --vh custom property dynamically
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);

    // Disable scroll snap on Android
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
      document.documentElement.style.scrollSnapType = 'none';
    }

    return () => {
      window.history.scrollRestoration = 'auto';
      window.removeEventListener('resize', setVh);

      // Reset scroll snap on Android when unmounting
      if (isAndroid) {
        document.documentElement.style.scrollSnapType = 'y mandatory';
      }
    };
  }, []);
  
  return (
    <div className="min-h-screen snap-y snap-mandatory bg-gray-900 text-gray-100">
      <Header />
      <Home />
      <Projects />
      <About />
      <Contact />
    </div>
  );
};

export default App;


//TODO: UNIFY BACKGROUND COLOR, MAYBE GRADIENT. MAKE HEADER. MAKE LOGO. PS BUT DO SOMETHING ARTSY WITH IT.