import React, { useEffect } from 'react';
import useScrollFadeIn from '../hooks/useScrollFadeIn';
import { FaEnvelope, FaLinkedin } from 'react-icons/fa'; // Add icons

const Contact = () => {
  useScrollFadeIn();  // Applying the fade-in effects

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <div id="contact" className="section min-h-screen py-20 bg-gradient-to-t from-gray-900 to-gray-800 flex flex-col justify-center items-center opacity-0" style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
      <h1 className="text-5xl font-bold mb-12">Contact Me</h1>
      <div className="space-y-6 text-center">
        <div className="flex items-center justify-center space-x-4">
          <FaEnvelope className="text-blue-400 text-2xl" />
          <a href="mailto:phillipsolis2@gmail.com" className="text-xl text-blue-400 hover:text-blue-300 transition">
            phillipsolis2@gmail.com
          </a>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <FaLinkedin className="text-blue-400 text-2xl" />
          <a href="https://linkedin.com/in/phillipsolis" className="text-xl text-blue-400 hover:text-blue-300 transition">
            linkedin.com/in/phillipsolis
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;