import { useEffect } from 'react';

const useScrollFadeIn = (setVisible) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.2 }
    );
    const elements = document.querySelectorAll('.section');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [setVisible]);
};

export default useScrollFadeIn;
