import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import useScrollFadeIn from '../hooks/useScrollFadeIn';

const Home = () => {
  useScrollFadeIn();  // This will add the fade-in effect when scrolled into view

  return (
    <div id="home" className="section min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-900 to-gray-800 text-center opacity-0 relative">
      <h1 className="text-6xl font-extrabold mb-4 text-white">Phillip Solis</h1>
      <p className="text-lg mb-8">Full-Stack Developer, Legal Assistant, Musician</p>
      <nav className="space-x-4">
        <a href="#projects" className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-6 rounded-lg transition duration-200">Projects</a>
        <a href="#about" className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-6 rounded-lg transition duration-200">About</a>
        <a href="#contact" className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-6 rounded-lg transition duration-200">Contact</a>
      </nav>

      {/* Floating Bubbles */}
      <div className="floating-icons">
        <a href="https://www.linkedin.com/in/phillipsolis" target="_blank" rel="noopener noreferrer" className="bubble">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://github.com/Phi1lS" target="_blank" rel="noopener noreferrer" className="bubble">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a href="https://www.instagram.com/ph1ll_r/" target="_blank" rel="noopener noreferrer" className="bubble">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.youtube.com/@Phi1ll-S" target="_blank" rel="noopener noreferrer" className="bubble">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
    </div>
  );
};

export default Home;